$(document).ready(function(){


	// Top Link
	$(".top").on('click', function(e){
		
		e.preventDefault();
		$('html, body').animate({
        	scrollTop: $("body").offset().top
    	}, 800);
		return false;
		
	    /*
	    try {
	        e.preventDefault();
	        $.scrollTo('0','0', {duration:800, axis:'y'} );
	    } catch (error) {
	        //console.log('error - '+error);
	    }
		return false;
		*/
	});
	
	
	
	// Set Anchor ID
	$('a[name]').each(function(){
		$(this).attr('id', $(this).attr('name') );
	});
	
	
	
	// Anchor Link
	$(".anchor").on('click', function(e){
		
		e.preventDefault();
	    // $.scrollTo( $(this).attr("href"), {duration:800, axis:'y'} );
		// $( $(this).attr("href") ).scrollTo(800);
		$('html, body').animate({
        	scrollTop: $( $(this).attr("href") ).offset().top
    	}, 800);
		
		/*
		e.preventDefault();
	    try {
	        e.preventDefault();
	        $.scrollTo( $(this).attr("href"), {duration:800, axis:'y'} );
	    } catch (error) {
	        //console.log('error - '+error);
	    }
	    return false;
	    */
		
	});


});