(function(lf, window, document, $, _, undefined) {

	$(document).ready(function(){

		var $metaSearch = $('#meta #search');
		var $metaSearchForm = $metaSearch.find('form');
		var $metaSearchLink = $metaSearch.prev('li').find('a');
		var $metaSearchButton = $metaSearch.find('button');
		var $metaSearchInput = $metaSearch.find('input');
		var $metaSearchLinkAndButton = $metaSearchButton.add($metaSearchLink);

		var moveOut = function () {

			$metaSearchForm.removeClass('open');
			$metaSearchForm.addClass('closed');
			$metaSearchLink.show();

			$metaSearchInput.velocity("transition.expandOut", { duration: 300, queue: false });
		};

		var moveIn = function () {

			$metaSearchForm.removeClass('closed');
			$metaSearchForm.addClass('open');
			$metaSearchLink.hide();

			$metaSearchInput.velocity("transition.expandIn", { duration: 100, queue: false });

			setTimeout(function () {

				$metaSearchInput.focus();
			}, 300);
			
		};

		$metaSearchLinkAndButton.on('click', function (event) {

			if( !($metaSearchForm.hasClass('open') && $(event.target).is('button')) ){

				event.preventDefault();
			} else if ($metaSearchInput.val() === '') {

				event.preventDefault();
			}
			
			if($metaSearchForm.hasClass('open')){

				moveOut();

			} else {

				moveIn();
			}
		});

		$metaSearchInput.on('blur', function () {

			if($metaSearchForm.hasClass('open')){

				moveOut();

			}
		});

	});
	
})(window.lf || (function () { window.lf = {}; return window.lf; })(), this, this.document, jQuery, _);