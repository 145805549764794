var $figuresContainer = document.querySelector('.teaserFigures');
var $figures = document.querySelectorAll('.js-count-up');

// Default count-up params
var startVal = 0;
var endVal = 999;
var decimalPlace = 0;
var duration = 2;
var useEasing = true;
var useGrouping = true;
var separator = '.';
var decimal = ';';
var prefix = '';
var suffix = '';
var numerals = [];

var isEnded = false;

// Sanity check
if (
	$figuresContainer &&
	'IntersectionObserver' in window &&
	'IntersectionObserverEntry' in window &&
	'intersectionRatio' in window.IntersectionObserverEntry.prototype
) {
	var observer = new IntersectionObserver(onIntersectionChange, { threshold: 1 });
	observer.observe($figuresContainer);

	function onIntersectionChange(intersections, observer) {
		intersections.forEach(function (intersection) {
			if (intersection.intersectionRatio === 1) {
				$figures.forEach(setUpCountUp);
				isEnded = true;
			}
		});
	}
} else if ($figuresContainer) {
	$figures.forEach(setUpCountUp);
}

// Set up count-up Animation
function setUpCountUp($figure) {
	startVal = $figure.dataset.startValue ? $figure.dataset.startValue : startVal;
	endVal = $figure.dataset.endValue ? $figure.dataset.endValue : endVal;
	decimalPlace = $figure.dataset.decimalPlace ? $figure.dataset.decimalPlace : decimalPlace;
	duration = $figure.dataset.duration ? $figure.dataset.duration : duration;
	options = {
		useEasing: $figure.dataset.easing ? $figure.dataset.easing : useEasing,
		useGrouping: $figure.dataset.grouping ? $figure.dataset.grouping : useGrouping,
		separator: $figure.dataset.separator ? $figure.dataset.separator : separator,
		decimal: $figure.dataset.decimal ? $figure.dataset.decimal : decimal,
		prefix: $figure.dataset.prefix ? $figure.dataset.prefix : prefix,
		suffix: $figure.dataset.suffix ? $figure.dataset.suffix : suffix,
		numerals: $figure.dataset.numerals ? $figure.dataset.numerals : numerals,
	};

	var countUpAnimation = new CountUp($figure, startVal, endVal, decimalPlace, duration, options);

	if (!countUpAnimation.error) {
		countUpAnimation.start();
	} else {
		console.error(countUpAnimation.error);
	}
}
