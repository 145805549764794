function Util() {}

/**
 *
 * Class manipulation
 *
 */

Util.hasClass = function (el, className) {
	if (el.classList) return el.classList.contains(className);
	else return !!el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
};

Util.addClass = function (el, className) {
	var classList = className.split(' ');
	if (el.classList) el.classList.add(classList[0]);
	else if (!Util.hasClass(el, classList[0])) el.className += ' ' + classList[0];
	if (classList.length > 1) Util.addClass(el, classList.slice(1).join(' '));
};

Util.removeClass = function (el, className) {
	var classList = className.split(' ');
	if (el.classList) el.classList.remove(classList[0]);
	else if (Util.hasClass(el, classList[0])) {
		var reg = new RegExp('(\\s|^)' + classList[0] + '(\\s|$)');
		el.className = el.className.replace(reg, ' ');
	}
	if (classList.length > 1) Util.removeClass(el, classList.slice(1).join(' '));
};

Util.toggleClass = function (el, className, bool) {
	if (bool) Util.addClass(el, className);
	else Util.removeClass(el, className);
};

Util.setAttributes = function (el, attrs) {
	for (var key in attrs) {
		el.setAttribute(key, attrs[key]);
	}
};

/**
 * Get children by class name
 */

Util.getChildrenByClassName = function (el, className) {
	var children = el.children,
		childrenByClass = [];
	for (var i = 0; i < el.children.length; i++) {
		if (Util.hasClass(el.children[i], className)) childrenByClass.push(el.children[i]);
	}
	return childrenByClass;
};

Util.is = function (elem, selector) {
	if (selector.nodeType) {
		return elem === selector;
	}

	var qa = typeof selector === 'string' ? document.querySelectorAll(selector) : selector,
		length = qa.length,
		returnArr = [];

	while (length--) {
		if (qa[length] === elem) {
			return true;
		}
	}

	return false;
};

/**
 *
 * Animate the height of an element
 *
 */

Util.setHeight = function (start, to, element, duration, cb) {
	var change = to - start,
		currentTime = null;

	var animateHeight = function (timestamp) {
		if (!currentTime) currentTime = timestamp;
		var progress = timestamp - currentTime;
		var val = parseInt((progress / duration) * change + start);
		element.style.height = val + 'px';

		if (progress < duration) {
			window.requestAnimationFrame(animateHeight);
		} else {
			cb();
		}
	};

	// Set the height of before starting the animation,
	// to circumvent a bug in Safari.
	element.style.height = start + 'px';
	window.requestAnimationFrame(animateHeight);
};

/**
 *
 * Smooth Scroll
 *
 */

// TODO: add back 'Math.easeInOutQuad'

Util.scrollTo = function (final, duration, cb, scrollEl) {
	var element = scrollEl || window;
	var start = element.scrollTop || document.documentElement.scrollTop,
		currentTime = null;

	if (!scrollEl) start = window.scrollY || document.documentElement.scrollTop;

	var animateScroll = function (timestamp) {
		if (!currentTime) currentTime = timestamp;
		var progress = timestamp - currentTime;
		if (progress > duration) progress = duration;
		var val = Math.easeInOutQuad(progress, start, final - start, duration);
		element.scrollTo(0, val);
		if (progress < duration) {
			window.requestAnimationFrame(animateScroll);
		} else {
			cb && cb();
		}
	};

	window.requestAnimationFrame(animateScroll);
};

/**
 *
 * Move focus to an element
 *
 */

Util.moveFocus = function (element) {
	if (!element) element = document.getElementsByTagName('body')[0];
	element.focus();
	if (document.activeElement !== element) {
		element.setAttribute('tabindex', '-1');
		element.focus();
	}
};

/**
 *
 * Check for session storage and that it actually works
 *
 */

Util.checkForSessionStorage = function () {
	var testString = 'this is the test string';
	var result;
	try {
		window.sessionStorage.setItem(testString, testString);
		result = window.sessionStorage.getItem(testString) === testString.toString();
		window.sessionStorage.removeItem(testString);
		return result;
	} catch (exception) {
		if (typeof console === 'undefined' || typeof console.log === 'undefined') {
			console.log('Notice: sessionStorage not available.');
		}
	}
};

/**
 *
 * Generate a unique string
 *
 * Allows for multiple instances of a component
 * without them conflicting with each other.
 * @see: https://stackoverflow.com/a/8809472
 *
 */

Util.generateUniqueID = function () {
	var d = new Date().getTime();

	if (typeof window.performance !== 'undefined' && typeof window.performance.now === 'function') {
		// use high-precision timer if available
		d += window.performance.now();
	}

	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		var r = (d + Math.random() * 16) % 16 | 0;
		d = Math.floor(d / 16);

		return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
	});
};

/*
  Misc
*/

Util.getIndexInArray = function (array, el) {
	return Array.prototype.indexOf.call(array, el);
};

Util.cssSupports = function (property, value) {
	if ('CSS' in window) {
		return CSS.supports(property, value);
	} else {
		var jsProperty = property.replace(/-([a-z])/g, function (g) {
			return g[1].toUpperCase();
		});
		return jsProperty in document.body.style;
	}
};

// Merge a set of user options into plugin defaults
// https://gomakethings.com/vanilla-javascript-version-of-jquery-extend/

Util.extend = function () {
	var extended = {};
	var deep = false;
	var i = 0;
	var length = arguments.length;

	// Check if a deep merge
	if (Object.prototype.toString.call(arguments[0]) === '[object Boolean]') {
		deep = arguments[0];
		i++;
	}

	// Merge the object into the extended object
	var merge = function (obj) {
		for (var prop in obj) {
			if (Object.prototype.hasOwnProperty.call(obj, prop)) {
				// If deep merge and property is an object, merge properties
				if (deep && Object.prototype.toString.call(obj[prop]) === '[object Object]') {
					extended[prop] = extend(true, extended[prop], obj[prop]);
				} else {
					extended[prop] = obj[prop];
				}
			}
		}
	};

	// Loop through each object and conduct a merge
	for (; i < length; i++) {
		var obj = arguments[i];
		merge(obj);
	}

	return extended;
};

/**
 * Check if 'prefers-reduced-motion' is enabled
 */

Util.osHasReducedMotion = function () {
	if (!window.matchMedia) return false;

	var matchMediaObj = window.matchMedia('(prefers-reduced-motion: reduce)');

	if (matchMediaObj) return matchMediaObj.matches;

	// return false if not supported
	return false;
};

// TODO: move globally useful fuctions in `utilities.js`

(function () {
	var backTop = document.getElementsByClassName('js-top-scroll')[0],
		offset = 800, // scroll until back to top shows
		scrollDuration = 700,
		scrolling = false;

	if (backTop) {
		// Update back to top visibility on scrolling

		window.addEventListener('scroll', function (event) {
			if (!scrolling) {
				scrolling = true;
				!window.requestAnimationFrame
					? setTimeout(checkBackToTop, 250)
					: window.requestAnimationFrame(checkBackToTop);
			}
		});

		// smooth scroll to top

		backTop.addEventListener('click', function (event) {
			event.preventDefault();
			!window.requestAnimationFrame ? window.scrollTo(0, 0) : scrollTop(scrollDuration);

			// move the focus to the #top-element - don't break keyboard navigation
			moveFocus(document.getElementById(backTop.getAttribute('href').replace('#', '')));
		});
	}

	// Set visibility class

	function checkBackToTop() {
		var windowTop = window.scrollY || document.documentElement.scrollTop;
		windowTop > offset
			? Util.addClass(backTop, 'is-visible')
			: Util.removeClass(backTop, 'is-visible');
		scrolling = false;
	}

	function scrollTop(duration) {
		var start = window.scrollY || document.documentElement.scrollTop,
			currentTime = null;

		var animateScroll = function (timestamp) {
			if (!currentTime) currentTime = timestamp;
			var progress = timestamp - currentTime;
			var val = Math.max(Math.easeInOutQuad(progress, start, -start, duration), 0);
			window.scrollTo(0, val);
			if (progress < duration) {
				window.requestAnimationFrame(animateScroll);
			}
		};

		window.requestAnimationFrame(animateScroll);
	}

	Math.easeInOutQuad = function (t, b, c, d) {
		t /= d / 2;
		if (t < 1) return (c / 2) * t * t + b;
		t--;
		return (-c / 2) * (t * (t - 2) - 1) + b;
	};

	// Move the focus

	function moveFocus(element) {
		if (!element) element = document.getElementsByTagName('body')[0];
		element.focus();
		if (document.activeElement !== element) {
			element.setAttribute('tabindex', '-1');
			element.focus();
		}
	}
})();
