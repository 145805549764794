$(document).ready(function(){

	$('#latestNews .slider').slick({
		slide : '.teaser',
		slidesToShow : 3,
		slidesToScroll: 3,
		autoplay : false,
		autoplaySpeed : 5000,
		arrows : false,
		dots: true,
		fade: false,
		cssEase: 'linear',
		responsive: [
    {
      breakpoint: 959,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 559,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
	});
	
	$('#upcomingEvents .slider').slick({
		slide : '.teaser',
		slidesToShow : 3,
		slidesToScroll: 3,
		autoplay : false,
		autoplaySpeed : 5000,
		arrows : false,
		dots: true,
		fade: false,
		cssEase: 'linear',
		responsive: [
    {
      breakpoint: 959,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 479,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
	});

	var sequenceOptions = {
        autoPlay: true,
        startingFrameID: 1,
        autoPlayDelay: 3000,
        preloader: true,
	    navigationSkip: false,
	    animateStartingFrameIn: true
    };
	
	var sequence = $('#statements').sequence(sequenceOptions).data('sequence');

	// $('#statements').slick({
	// 	slide : '.image',
	// 	slidesToShow : 1,
	// 	slidesToScroll: 1,
	// 	autoplay : true,
	// 	autoplaySpeed : 3000,
	// 	arrows : false,
	// 	dots: false,
	// 	fade: false,
	// 	cssEase: 'ease-in-out'
	// })
	
	$('#vipTeaser .slider').slick({
		slide : '.row',
		slidesToShow : 1,
		slidesToScroll: 1,
		autoplay : true,
		autoplaySpeed : 5000,
		arrows : false,
		dots: true,
		fade: true,
		cssEase: 'linear'
	});
	
	var teaserHeightManage = function () {

		var teaserMinHeight = null;

		if (matchMedia('only screen and (max-width: 959px)').matches) {
			
			$('.teaserM').css("min-height","");

		} else {
			
			/*
			if ( $('#latestNews').length > 0 ) {
			
				teaserMinHeight = 200;
				$('.teaserM').each(function(){
					
					if ( ($(this).height()) > teaserMinHeight ) {
						teaserMinHeight = $(this).height() + 40 ;
					}
				});
				$('.teaserM').css("min-height",teaserMinHeight);
			
			}
			*/
			
			if ( $('.related').length > 0 ) {
			
				teaserMinHeight = 200;
				$('.related .teaserM').each(function(){
					
					if ( ($(this).height()) > teaserMinHeight ) {
						teaserMinHeight = $(this).height() + 60 ;
					}
				});
				$('.related .teaserM').css("min-height",teaserMinHeight);
			
			}
		}
	};

	setTimeout(function () {
		teaserHeightManage();
	}, 1000);
	

	$(window).on('resize', teaserHeightManage);
	
});