/**
 * randomContent
 */


$(document).ready(function(){

	var $randomContent = $('.randomContent');

	if($randomContent.length < 1){
		return;
	}
	
	// alert( "randomContent: " + $randomContent.length );

	$randomContent.each(function () {

		var $this = $(this);

		var childrenLength = $this.find('> *').length;

		childrenToShowIndex = _.random(0, (childrenLength - 1));

		$this.find('> *').eq(childrenToShowIndex).addClass("js-randomContent__show"); //.css("left","auto").css("position","relative!important"); //.show();
	
	});
	
});