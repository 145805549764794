// Achtung: Muss nach forms.js geladen werden, da sonst Problem mit Select2 und sisyphus
$(document).ready(function () {
	function initNicescroll(element) {
		if ($('.select2-results').getNiceScroll().length <= 0) {
			$('.select2-results').niceScroll({
				cursorcolor: '#181717',
				cursoropacitymin: 0.5,
				cursoropacitymax: 1,
				cursorminheight: 40,
				railpadding: { top: 0, right: 0, left: 0, bottom: 0 },
				cursorwidth: 8,
				cursorborder: '5px solid transparent',
				cursorborderradius: '10px',
				scrollspeed: 40,
				mousescrollstep: 20,
				enabletranslate3d: true,
				smoothscroll: true,
				bouncescroll: false,
			});
		}
	}

	function removeNicescroll() {
		if ($('.select2-results').getNiceScroll().length > 0) {
			$('.select2-results').getNiceScroll().remove();
		}
	}

	/*
	
	$("#contactForm #subject").select2({
		allowClear: true
	}).on("select2-open", function() {
		$('#select2-drop .select2-search').hide();
		$('#select2-drop').removeClass("filter-drop");
	});
	


	$(".themefilter select")
	.select2({
		dropdownCssClass : 'themefilter-dropdown'
	})
	.on("select2-open", function() {
		$('#select2-drop .select2-search').hide();
		$('#select2-drop').addClass("filter-drop");
		initNicescroll();
	}).on("select2-close", function() {
		$('#select2-drop').removeClass("filter-drop");
		removeNicescroll();
	})
	.on("change", function(){
		if ( this.options[this.selectedIndex].value == "" ) {
			//window.location='http://'+window.location.hostname+'/index.php?id=115/';
		} else {
			jumpMenu( 'parent',this,0);
		}
	});
	
	
	
	
	// SEMINAR BUNDESLAND 
	$("#seminarForm #state").select2({
		allowClear: true
	}).on("select2-open", function() {
		$('#select2-drop .select2-search').hide();
		initNicescroll();
	}).on("select2-close", function() {
		removeNicescroll();
	});
	
	// SEMINAR LAND
	$("#seminarForm #country").select2({
		allowClear: true
	}).on("select2-open", function() {
		// $('#select2-drop .select2-search').hide();
		initNicescroll();
	}).on("select2-close", function() {
		removeNicescroll();
	});
	
	*/
});

function jumpMenu(targ, selObj, restore) {
	window.location =
		'http://' + window.location.hostname + '/' + selObj.options[selObj.selectedIndex].value;
	if (restore) selObj.selectedIndex = 0;
}
