$(document).ready(function () {
	// Change color of corresponding link on hover over map pin
	$('.lfv-map__svg .pin').hover(
		function () {
			// fade in label
			$(this).parent().find($('text')).fadeIn(200);

			// highlight background color of area
			$('#' + $(this).attr('data-org') + '-map').css('fill', '#EB8E00');

			// highlight corrosponding link
			$('#' + $(this).attr('data-org') + '-link').css('color', '#D32E17');
		},

		function () {
			// fade out label
			$(this).parent().find($('text')).fadeOut(200);

			// reset background color of area
			$('#' + $(this).attr('data-org') + '-map').css('fill', '#EBBD00');

			// highlight corrosponding link
			$('#' + $(this).attr('data-org') + '-link').css('color', '#000');
		}
	);

	// Change color of corresponding pin and state on hover over link list item
	$('.lfv-map-links a').hover(
		function () {
			// fade in label
			$('#' + $(this).attr('data-org') + '-text').fadeIn(200);

			// highlight background color of area
			$('#' + $(this).attr('data-org') + '-map').css('fill', '#EB8E00');
		},

		function () {
			// fade out label
			$('#' + $(this).attr('data-org') + '-text').fadeOut(200);

			// reset background color of area
			$('#' + $(this).attr('data-org') + '-map').css('fill', '#EBBD00');
		}
	);

	// Link to corresponding Landesverein on click
	$('.lfv-map__svg .pin').on('click', function (event) {
		$('#' + $(this).attr('data-org') + '-link')[0].click();
	});
});
