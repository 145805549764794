$(document).ready(function() {
	
	var $galleryPage = jQuery('.gallery .page');
	
	$galleryPage.imagesLoaded( function(){
		 
		$galleryPage.isotope({
			itemSelector: '.media'
		});
		
	});
	
	/*
	jQuery(".gallery .page").isotope({
		itemSelector: '.media'
	});
	*/
	
	jQuery(".gallery .actions .zoom").on("click",function(e){
		e.preventDefault();
		jQuery(this).parent().parent().find(".lightbox").click();
	});
	
});