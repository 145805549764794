$(document).ready(function(){


	// Videos & Audios MediaElement.js
	// var players = new Array();
	// var listeners = new Array();
	// var thisDuration = 0;
	// var lastPlayed = null;
	
	var ios = false;
	if ( (navigator.userAgent.match(/Android/i)) || 
	(navigator.userAgent.match(/iPhone/i)) || 
	(navigator.userAgent.match(/iPod/i)) || 
	(navigator.userAgent.match(/iPad/i)) ) {
	
		ios = true;
	
	}
	
	/*
	if ( ios ) {
		$('video.youtube').attr('poster', '' );
	}
	
	
	$('video').each(function(){
		
		//
		$(this).mediaelementplayer({
			enablePluginDebug: false,
			enableAutosize: true,
			//plugins: ['flash','silverlight'],
			pluginPath: '/typo3conf/ext/lf_base/Resources/Public/assets/statics/mediaelement/',
			flashName: 'flashmediaelement.swf',
			silverlightName: 'silverlightmediaelement.xap',
			//defaultVideoWidth: 640,
			//defaultVideoHeight: 480,
			pluginWidth: -1,
			pluginHeight: 1,
			success: function(media, node, player) {
				$('#' + node.id + '-mode').html('mode: ' + media.pluginType);
			}			
		});
	});
	
	
	$('audio').each(function(){
	
		//
		$(this).mediaelementplayer({
			enablePluginDebug: false,
			enableAutosize: true,
			//plugins: ['flash','silverlight'],
			pluginPath: '/typo3conf/ext/lf_base/Resources/Public/assets/statics/mediaelement/',
			flashName: 'flashmediaelement.swf',
			silverlightName: 'silverlightmediaelement.xap',
			plugins: ['flash','silverlight'],
			success: function(media, node, player) {
				$('#' + node.id + '-mode').html('mode: ' + media.pluginType);
			}
		});
		
	});
	*/
	
});


$(document).ready(function() {
	
	$('.video__loadinfo .video__button').on("click",function(){
		
		$(this).parent().parent().parent().html( '<iframe class="" width="' + $(this).attr('data-embed-width') + '" height="' + $(this).attr('data-embed-height') + '" src="' + $(this).attr('data-embed-url') + '" frameborder="0" allowfullscreen></iframe>' );
		
	});

});