$(document).ready(function(){
	
	$("#newsFilter #yearFilter, #newsFilter #monthFilter, #newsFilter #themeFilter")
	.select2({
		allowClear: true
	})
	.on("select2-open", function() {
		$('#select2-drop .select2-search').hide();
		//initNicescroll();
	})
	.on("select2-close", function() {
		//removeNicescroll();
	})
	.on("change", function(){
		$('.filter form').submit();
	});
	
	
	
	$("#newsList .top:first").hide()

	/*	
	$(".form #themeFilter")
	.select2({
		allowClear: true
	})
	.on("select2-open", function() {
		$('#select2-drop .select2-search').hide();
		$('#select2-drop').addClass("filter-drop");
		initNicescroll();
	})
	.on("select2-close", function() {
		$('#select2-drop').removeClass("filter-drop");
		removeNicescroll();
	})
	.on("change", function(){
		if ( this.options[this.selectedIndex].value == "" ) {
			window.location='http://'+window.location.hostname+'/aktuell/';
		} else {
			jumpMenu('parent',this,0);
		}
	});
	*/
	/*
	$(".form #monthFilter")
	.select2({
		// allowClear: true
	})
	.on("select2-open", function() {
		$('#select2-drop .select2-search').hide();
		$('#select2-drop').addClass("filter-drop");
		initNicescroll();
	})
	.on("select2-close", function() {
		$('#select2-drop').removeClass("filter-drop");
		removeNicescroll();
	})
	.on("change", function(){
		//$.scrollTo( ".month-" + this.options[this.selectedIndex].value ,600, {'axis':'y'});
		if ( this.options[this.selectedIndex].value == "" ) {
			window.location='http://'+window.location.hostname+'/aktuell/';
		} else {
			jumpMenu( 'parent',this,0);
		}
	});
	
	
	$(".form #yearFilter")
	.select2({
		// allowClear: true
	})
	.on("select2-open", function() {
		$('#select2-drop .select2-search').hide();
		$('#select2-drop').addClass("filter-drop");
		initNicescroll();
	})
	.on("select2-close", function() {
		$('#select2-drop').removeClass("filter-drop");
		removeNicescroll();
	})
	.on("change", function(){
		if ( this.options[this.selectedIndex].value == "" ) {
			window.location='http://'+window.location.hostname+'/aktuell/';
		} else {
			jumpMenu( 'parent',this,0);
		}
	});
*/
	
});