$(document).ready(function(){

	var $carouselSlick = $('.carousel-slick');

	$carouselSlick.slick({
		slide : 'a',
		slidesToShow : 3,
		slidesToScroll: 3,
		autoplay : true,
		autoplaySpeed : 5000,
		arrows : true,
		dots: true,
		prevArrow: '<div class="slick-prev"><i></i><span>Previous</span></div>',
		nextArrow: '<div class="slick-next"><i></i><span>Next</span></div>',
		responsive: [
		/*
		    {
		    	breakpoint: 960,
		    	settings: {
		        	slidesToShow: 2,
					slidesToScroll: 1
		    	}
		    },
		    */
		   
		    {
		    	breakpoint: 640,
		    	settings: {
		        	slidesToShow: 2,
					slidesToScroll: 2
		    	}
		    },
		    {
		    	breakpoint: 480,
		    	settings: {
		        	slidesToShow: 1,
					slidesToScroll: 1
		    	}
		    }
		    
		]
	});
});