$(document).ready(function(){
	
	
	$(".accordeon").each(function(){
	
		//
		var accContainer = this;
		var accStayOpened = false;
		if ( $(this).hasClass("firstopened") ) var accFirstOpened = true;
		
		
		//
		$('h3',accContainer).click(function() {
			
			if ( $(accContainer).hasClass("stayopened") ) accStayOpened = true;
			
			if ( $(this).parent().hasClass('open') ) {	
				$(this).parent().removeClass('open');
			} else {
				if ( accStayOpened == false ) {
					$('h3',accContainer).parent().removeClass('open');
				}
				$(this).parent().addClass('open');
			}
			
		});
		
		
		//
		if ( accFirstOpened === true ) {
			
			$(".item",accContainer).eq(0).addClass("open");
		
		}
		
		
	});


});