// toggle the mobile nav

$(document).ready(function () {
	var $slideNavCheckbox = $('#slide-nav-checkbox-root');
	var $slideNavToggle = $('.js-slide-nav-toggle');

	// 	uncheckAll();

	$slideNavToggle.on('click', function (e) {
		if ($slideNavCheckbox.prop('checked') === false) {
			$slideNavCheckbox.prop('checked', true);
			$slideNavToggle.children('span').text('Menü schließen');

			// console.log('new toggle is clicked to open');
			// console.log('root checkbox is ' + $slideNavCheckbox.prop( 'checked' ) );
		} else {
			$slideNavCheckbox.prop('checked', false);
			$slideNavToggle.children('span').text('Menü öffnen');

			// console.log('new toggle is clicked to close');
			// console.log('root checkbox is ' + $slideNavCheckbox.prop( 'checked' ) );
		}

		e.preventDefault();

		return false;
	});
});

// navigation-mobile: close all sub-navs on close

$(document).ready(function () {
	var $body = $('body');
	var $nav = $('.slide-nav-root');
	var $navCheckboxes = $nav.find('.slide-nav-checkbox');
	var $rootCheckbox = $('#slide-nav-checkbox-root');

	$rootCheckbox.on('change', function (event) {
		if ($rootCheckbox.prop('checked') === true) {
			$body.addClass('slide-nav-is-open');
			console.log('root checkbox is checked');
		} else {
			$body.removeClass('slide-nav-is-open');
			$navCheckboxes.prop('checked', false);
			console.log('root checkbox is not checked');
		}
	});
});

// navigation-mobile: scroll up on checkbox change

$(document).ready(function () {
	var $navCheckboxes = $('.slide-nav-checkbox');

	$navCheckboxes.on('change', function () {
		$('.slide-nav-root, .slide-nav-root *').scrollTop(0);
	});
});

/*
$(document).ready(function() {

	var labelId;

	$('.pseudo-label').on('click', function(e) {

		console.log('pseudo label was clicked');

		labelId = $(this).children('label').attr('for');

       	$('#'+labelId).trigger('click');

	   	e.preventDefault();
		return false

	});

});
*/

/*

$(document).ready(function() {

	$('.slide-nav-checkbox').focus(function() {

		$checkboxId = $(this).attr('id');

		$('label[for="' + $checkboxId + '"]').addClass('has-focus');

	}).blur(function() {
		$('label[for="' + $checkboxId + '"]').removeClass('has-focus');
	});

});
*/
