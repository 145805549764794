jQuery(document).ready(function() {


	if( $(".share").length > 0 ){
		
		/*
			
		//
		var currentLocation = $(".share .twitter").attr("data-location");
		if(!currentLocation) {
			currentLocation = encodeURIComponent( $("meta[property='og:url']").attr("content") );
		}
		
		var currentTitle = $(".share .twitter").attr("data-title");
		if(!currentTitle) {
			currentTitle= encodeURIComponent( $("meta[property='og:title']").attr("content") );
					
		}
		var currentSiteName= encodeURIComponent( $("meta[property='og:site_name']").attr("content") );
		var currentDesc= encodeURIComponent( $("meta[property='og:description']").attr("content") );
		var currentImage= $("meta[property='og:image']").attr("content");
		
				
		if(currentDesc == 'undefined') {
			currentDesc = '';
		}
		if (currentSiteName == 'undefined') {
			currentSiteName = '';
		}
		
		
		//
		$(".share .twitter").attr(
			"href",
			'https://twitter.com/share?url=' + currentLocation + 
				'&text=' + currentTitle
		);
		
		
		//
		$(".share .facebook").attr(
			"href",
			'https://www.facebook.com/sharer/sharer.php?s=140' +
				'&p[url]=' + currentLocation + 
				'&p[title]=' + currentTitle + 
				'&p[summary]=' + currentDesc + 
				'&p[images][0]=' + currentImage
		);
		
				
		//
		$(".share .gplus").attr(
			"href",
			'https://plus.google.com/share?hl=de&url=' + currentLocation
		);
		
		*/
		
		//
		$(".share .twitter, .share .facebook").on("click",function(e){
			e.preventDefault();
			window.open(this.href,'targetWindow','toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes, width=600,height=500'); 
			return false;
		});	
		
		
		//
		/*
		$(".share .email").attr(
			"href",
			'/seite-versenden/?tx_limerecommend_form[url]=' + currentLocation
		);
		*/
		
		/*
		$(".share .email").attr(
			"href",
			'mailto:?subject=Empfehlung: "' + currentTitle + '" von ' + currentSiteName + '&body=' + currentTitle + '%0D%0A'+ currentDesc + '%0D%0A%0D%0A' + currentLocation
		);
		*/
	
	}
	
	
	//
	/*
	$(".share a.email").on("click", function(e){
		e.preventDefault();
					
		var url = $(this).attr("href");		
		var height = $('body').height() - 100;
		
		//
		$.fancybox( url ,
			{
				margin: 0,
				padding: 0,
				autoSize: false,
				fitToView: false,
				fixed: true,
				arrows: false,
				closeBtn: false,
				loop: false,
				width: 640,
				height: height,
				type: 'iframe'
			}
		);
		
	});
	*/
	
	//	
	$( "#sharePerMail .close").click(function(e){
		e.preventDefault();
		//$.fancybox.close();
		parent.$.fancybox.close();
	});
	
	
	//
	$('.print').on('click', function(e){
		e.preventDefault();
		window.print();
	});
	
	
	$(".share").each(function(){
		if( $(this).next().next().hasClass("top") ) {
			$(this).addClass("share-last-child").next().next().addClass("top-last-child");
		}
		
	});
	
	if( $(".share").next().next().hasClass("top") ) {
		$(".share").addClass("share-last-child");
		$(".share").next().next().addClass("top-last-child");
	}
	
	if( $(".share").next().next().hasClass("back") ) {
		$(".share").addClass("share-last-child");
		$(".share").next().next().addClass("back-last-child");
	}
	
});