$(document).ready(function(){
			
	$("#publicationFilter #themeFilter, #publicationFilter #typeFilter, #publicationFilter #yearFilter")
	.select2({
		allowClear: true
	})
	.on("select2-open", function() {
		$('#select2-drop .select2-search').hide();
		//initNicescroll();
	})
	.on("select2-close", function() {
		//removeNicescroll();
	})
	.on("change", function(){
		
		$('.filter form').submit();
	});
	/*
	.on("select2-open", function() {
		$('#select2-drop .select2-search').hide();
		$('#select2-drop').addClass("filter-drop");
		initNicescroll();
	})
	.on("select2-close", function() {
		$('#select2-drop').removeClass("filter-drop");
		removeNicescroll();
	})
	.on("change", function(){
		if ( this.options[this.selectedIndex].value == "" ) {
			window.location='http://'+window.location.hostname+'/publikationen/';
		} else {
			jumpMenu('parent',this,0);
		}
	});
	
	$(".form #typeFilter")
	.select2({
		// allowClear: true
	})
	.on("select2-open", function() {
		$('#select2-drop .select2-search').hide();
		$('#select2-drop').addClass("filter-drop");
		initNicescroll();
	}).on("select2-close", function() {
		$('#select2-drop').removeClass("filter-drop");
		removeNicescroll();
	}).on("change", function(){
		if ( this.options[this.selectedIndex].value == "" ) {
			window.location='http://'+window.location.hostname+'/publikationen/';
		} else {
			jumpMenu('parent',this,0);
		}
	});
	*/
	
});