$(document).ready(function(){

	$('.slideshow-slick .caption').css('opacity','0');

	var $slideshowSlick = $('.slideshow-slick');

	var centerNav = function (event, that) {

		var $list = that.$list;

		var imageHeight = $list.find('.image').eq(0).height();

		var $prevNext = $list.parent().find('.slick-prev, .slick-next');
		var prevNextHeight = $prevNext.height();
		var prevNextTop = '' + ( imageHeight / 2) - ( prevNextHeight / 2) + 'px';

		$prevNext.css({
			'top' : prevNextTop,
			'margin-top' : '0px',
		});
	};

	var initCaption = function (event, slick) {

		$('.slideshow-slick .slick-slide').eq(1).find('.caption, .credits, i').velocity({
			opacity: 1
		}, {
			duration: 300
		});
	};

	var fadeOutCaptions = function (event, slick, currentSlide, nextSlide) {

		$('.slideshow-slick .slick-slide').find('.caption, .credits, i').velocity({
			opacity: 0
		}, {
			duration: 0
		});
	};

	var fadeInCaption = function (event, slick, currentSlide) {

		$('.slideshow-slick .slick-slide').eq(currentSlide + 1).find('.caption, .credits, i').velocity({
			opacity: 1
		}, {
			duration: 300
		});
	};

	$slideshowSlick.on('init', initCaption); // init vor initialisierung binden! Sonst nischt feuer!

	$slideshowSlick.on('setPosition', centerNav);

	$slideshowSlick.on('beforeChange', fadeOutCaptions);

	$slideshowSlick.on('afterChange', fadeInCaption);

	$slideshowSlick.slick({
		slide : '.media',
		slidesToShow : 1,
		autoplay : true,
		autoplaySpeed : 5000,
		arrows : true,
		dots: true,
		speed: 300,
		prevArrow: '<div class="slick-prev"><i></i><span>Previous</span></div>',
		nextArrow: '<div class="slick-next"><i></i><span>Next</span></div>',
	});

});



$(document).ready(function(){

	// $('.quote-slick .caption').css('opacity','0');

	var $quoteSlick = $('.quote-slick');
/*
	var centerNav = function (event, that) {

		var $list = that.$list;

		var imageHeight = $list.find('.image').eq(0).height();

		var $prevNext = $list.parent().find('.slick-prev, .slick-next');
		var prevNextHeight = $prevNext.height();
		var prevNextTop = '' + ( imageHeight / 2) - ( prevNextHeight / 2) + 'px';

		$prevNext.css({
			'top' : prevNextTop,
			'margin-top' : '0px',
		});
	};

	var initCaption = function (event, slick) {

		$('.quote-slick .slick-slide').eq(1).find('.caption, .credits, i').velocity({
			opacity: 1
		}, {
			duration: 300
		});
	};

	var fadeOutCaptions = function (event, slick, currentSlide, nextSlide) {

		$('.quote-slick .slick-slide').find('.caption, .credits, i').velocity({
			opacity: 0
		}, {
			duration: 0
		});
	};

	var fadeInCaption = function (event, slick, currentSlide) {

		$('.quote-slick .slick-slide').eq(currentSlide + 1).find('.caption, .credits, i').velocity({
			opacity: 1
		}, {
			duration: 300
		});
	};

	$slideshowSlick.on('init', initCaption); // init vor initialisierung binden! Sonst nischt feuer!

	$slideshowSlick.on('setPosition', centerNav);

	$slideshowSlick.on('beforeChange', fadeOutCaptions);

	$slideshowSlick.on('afterChange', fadeInCaption);

*/

	$quoteSlick.slick({
		slide : '.blockquote',
		slidesToShow : 1,
		autoplay : false,
		autoplaySpeed : 5000,
		arrows : true,
		adaptiveHeight: true,
		dots: true,
		speed: 300,
		prevArrow: '<div class="slick-prev"><i></i><span>Previous</span></div>',
		nextArrow: '<div class="slick-next"><i></i><span>Next</span></div>',
	});

});