// Hover Intent
// determine the user's intent to open the flyout

$(document).ready(function () {
	var $mainNavItem = $('.js-nav-main__item-level-1');

	function doOnOver() {
		$(this).addClass('is-open');
	}

	function doOnOut() {
		$(this).removeClass('is-open');
	}

	$mainNavItem.addClass('no-hover');

	$mainNavItem.hoverIntent({
		over: doOnOver,
		out: doOnOut,
		timeout: 250,
	});
});

// Accessibly toggle the flyout

$(document).ready(function () {
	$('.js-nav-main__sub-toggle').on('click', function (e) {
		// var toggleText = $(this).children('span').text();
		var toggleButton = $(this);

		if ($(this).attr('aria-expanded') === 'false') {
			// Set up focusable vars for menu
			var focusableSecondNav, firstfocusableSecondNav, lastfocusableSecondNav;

			// Get all, first and last focusable elements from the Menu.
			focusableSecondNav = toggleButton
				.parent('span')
				.parent('li')
				.children('ul')
				.find('select, input, textarea, button, a')
				.filter(':visible');
			firstfocusableSecondNav = focusableSecondNav.first();
			lastfocusableSecondNav = focusableSecondNav.last();

			// Redirect last tab to first
			lastfocusableSecondNav.on('keydown', function (e) {
				if (e.keyCode === 9 && !e.shiftKey) {
					e.preventDefault();
					toggleButton.focus();

					// console.log('yoyo ' + toggleButton);
				}
			});

			// Redirect first shift+tab to last
			firstfocusableSecondNav.on('keydown', function (e) {
				if (e.keyCode === 9 && e.shiftKey) {
					e.preventDefault();
					toggleButton.focus();
				}
			});

			$(this).attr('aria-expanded', 'true').children('span').text('Untermenü schließen');
			$(this).prev('a').attr('aria-expanded', 'true');
			$(this).parent().parent('.nav-main__item-level-1 ').addClass('is-open');
		} else {
			$(this).attr('aria-expanded', 'false').children('span').text('Untermenü öffnen');
			$(this).prev('a').attr('aria-expanded', 'false');
			$(this).parent().parent('.nav-main__item-level-1 ').removeClass('is-open');
		}
	});
});
