$(document).ready(function () {
	// inital click
	$('.checkbox input, .radio input').each(function () {
		if ($(this).prop('checked') === true && $(this).prop('disabled') !== true) {
			if ($(this).parent().hasClass('checkbox')) {
				checkboxClick($(this).parent().find('label'));
			}

			if ($(this).parent().hasClass('radio')) {
				$(this).parent().find('label').removeClass('unchecked').addClass('checked');
				radioClick($(this).parent().find('label'));
			}
		}
	});

	// Radiobutton
	$('.radio label').click(function () {
		if ($(this).prev().prop('disabled') !== true) {
			var radioGroup = $(this).prev().attr('name');
			$('input[name="' + radioGroup + '"]')
				.parent()
				.find('label')
				.removeClass('checked')
				.addClass('unchecked');
			$(this).removeClass('unchecked').addClass('checked');

			radioClick($(this));
		}
	});

	// SEMINARFORM

	// checkbox
	$('.checkbox label').click(function () {
		if ($(this).prev().prop('disabled') !== true) {
			checkboxClick($(this));
		}
	});

	// checkbox with link (e.g. agb, etc.)
	$('.checkbox label a, .radio label a').click(function (e) {
		e.preventDefault();
		window.open($(this).attr('href'), '_blank');
		return false;
	});

	function checkboxClick(checkboxItem) {
		if (checkboxItem.hasClass('checked')) {
			if (checkboxItem.attr('for') == 'showPassword') {
				$('.password').attr('type', 'password');
			}

			checkboxItem.removeClass('checked').addClass('unchecked');
		} else {
			if (checkboxItem.attr('for') == 'showPassword') {
				$('.password').attr('type', 'text');
			}

			checkboxItem.removeClass('unchecked').addClass('checked');
		}
	}

	function radioClick(radioItem) {}
});
