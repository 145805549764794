$(document).ready(function () {
	var $hase = $('.hase');
	var $eimer = $('.eimer');

	// $hase.velocity('callout.tada');
	$hase.velocity('transition.bounceIn');

	$hase.on('mouseenter', function (event) {
		$hase.velocity('callout.tada');
	});

	$('.eimer').on('mouseenter', function (event) {
		$hase.velocity('callout.bounce');
	});
});
