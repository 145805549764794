$(document).ready(function(){
	
	$('.seminar .radio label').off('click');
	
	$('.seminar .radio label').on('click', function (event) {

		event.preventDefault();

		var $thisLabel = $(this);

		var $thisInput = $thisLabel.prev('input');

		if ($thisInput.prop('disabled')){ return; }

		var $inputsByName = $('input[name="'+ $thisInput.attr('name') +'"]');

		var $labelsByName = $inputsByName.next('label');

		var setAllInputsUnchecked = function () {

			$inputsByName.prop('checked', false);
			$labelsByName.removeClass('checked').addClass('unchecked');
		};
		
		if($thisInput.prop('checked')){

			setAllInputsUnchecked();
		} else {

			setAllInputsUnchecked();

			$thisInput.prop('checked', true);
			$thisLabel.removeClass('unchecked').addClass('checked');
		}
	});
	
	$("#seminarFilter #yearFilter, #seminarFilter #monthFilter")
	.select2({
		allowClear: true
	})
	.on("select2-open", function() {
		$('#select2-drop .select2-search').hide();
		//initNicescroll();
	})
	.on("select2-close", function() {
		//removeNicescroll();
	})
	.on("change", function(){
		$('.filter form').submit();
	});
	
	
	$("#seminarForm select")
	.select2({
		allowClear: true,
		formatNoMatches: function(m) { return 'Keine Ergebnisse'; }
	});

	var $forms = $('#seminarForm');
	if($forms.length < 1){
		return;
	}
	
	

	$('#birthdate').pickadate({
		format: 'dd.mm.yyyy',
		formatSubmit: 'dd.mm.yyyy',
		selectYears: 100,
		selectMonths: true,
		min: -36500,
		max: true,
		today: false,
		clear: false,
	});

	$('#date_anreise, #date_abreise').pickadate({
		format: 'dd.mm.yyyy',
		formatSubmit: 'dd.mm.yyyy',
		selectMonths: true,
		min: true,
		today: false,
		clear: false,
	});





	
	
	
	
	
	

});

// SUBMIT LOADER
/*
$(document).ready(function(){

	var $form = $('#seminarForm');

	var $state = $form.find('#state');

	var $country = $form.find('#country');

	if($country.val() === 'Deutschland' || parseInt($country.val(), 10) === 54){

		$state.attr("required", "required");
	} else {

		$state.removeAttr("required");
	}

	$country.on('change', function (event) {

		var val = $(this).val();

		if(val === 'Deutschland' || parseInt($country.val(), 10) === 54){

			$state.attr("required", "required");
		} else {

			$state.removeAttr("required");
		}
	});
});
*/