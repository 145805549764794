$(document).ready(function() {
	var $headerSticky = $('.js-header-sticky');

	if ($headerSticky.length) {
		$headerSticky.elementDirectionAndOffsetByScroll('init', {
			container: $(document),
			offsetSmall: 480 - 80, // Best practice: header-height - header-small-height
			offsetOut: 680, // Best practice: header-height
			classInit: 'is-ready',
			classScrollDirectionDown: 'is-scrolling-down',
			classScrollDirectionUp: 'is-scrolling-up',
			classOffsetSmall: 'is-small',
			classOffsetOut: 'will-hide-and-show',
			callbackScrollDirection: function(scrollDirection) {
				// console.log('scroll direction: ' + scrollDirection);
			},
			callbackOffsetSmall: function(scrollOffsetSmall) {
				// console.log('can show and hide: ' + scrollOffsetSmall);
			},
		});
	}
});
