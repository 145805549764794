// SUBMIT LOADER
/*
$(document).ready(function(){

	var $forms = $('form');
	
	// $forms.removeClass('submitting');
	// After travelling back in Firefox history, javascript won't run so...
	$(window).on('pageshow', function (event) {
		if (event.originalEvent.persisted) {
			$forms.removeClass('submitting');
		}
	});

	var timeout = 20;

	var submitting = function (event) {
		var $this = $(this);

		event.preventDefault();

		if (!$this.hasClass('submitting')) {
			$this.addClass('submitting');

			setTimeout(function () {
				$this.off('submit.submitting');
				$this.submit();
				$this.on('submit.submitting', submitting);
			}, timeout);
		}
	};

	$forms.each(function (element, index) {
		var $this = $(this);
		var $button = $this.find('input[type="submit"], button[type="submit"]');

		if ($button.length > 0) {
			$this.on('submit.submitting', submitting);
		}
	});
	
	
});
*/
