$(document).ready(function () {
	window.ParsleyConfig = {
		//errorsWrapper: '<div class="error-wrap parsley-errors-list"><ul></ul></div>',
		//errorTemplate: '<span></span>'
	};

	window.ParsleyValidator.addValidator(
		'germandate',
		function (value, requirement) {
			// Datum im Format TT.MM.JJJJ von Jahr 1900 - 2999
			if (
				/^([0][1-9]|[1-2][0-9]|[3][0-1])[.](0[1-9]|1[0-2])[.](19[0-9]{2}|2[0-9]{3}|2[0-9]{3})$/.test(
					value
				)
			) {
				return true;
			} else {
				return false;
			}
		} //,32 // priority
	).addMessage('de', 'germandate', 'Dieses Feld muss ein Datum im Format: TT.MM.JJJJ enthalten.');

	window.ParsleyValidator.addValidator(
		'phonenumber',
		function (value, requirement) {
			if (/^[0-9\+\(\)\/\-\s\.]+$/gi.test(value)) {
				return true;
			} else {
				return false;
			}
		} //,32 // priority
	).addMessage('de', 'phonenumber', 'Dieses Feld muss eine gültige Telefonnummer enthalten.');

	window.ParsleyValidator.addValidator(
		'securepassword',
		function (value) {
			// Min. 8 Stellen inkl. min. einer Ziffer und min. ein Sonderzeichen. Max. 20 Stellen
			if (/^(?=.{8,20}$)((?=.*[a-z])(?=.*[A-Z]))(?=.*\d)(?=.*\W)\S*$/.test(value)) {
				return true;
			} else {
				return false;
			}
		} //,32 // priority
	).addMessage(
		'de',
		'securepassword',
		'Das Passwort muss mindestens acht Zeichen lang sein, einen Groß- und einen Kleinbuchstaben, eine Ziffer und ein Sonderzeichen enthalten.'
	);

	var errTempl =
		'<div id="generalParsleyError" class="notice error" >' +
		'<p>Es ist ein Fehler aufgetreten. Bitte versuchen Sie es nochmal.</p>' +
		'</div>';

	if ($('form:not([data-noparsley])').length > 0) {
		$('form:not([data-noparsley])').parsley({
			excluded: 'input[type=button], input[type=submit], input[type=reset]',
		});

		$.listen('parsley:field:error', function (parsleyField) {
			if (
				parsleyField.parent.$element.is('form') &&
				parsleyField.parent.$element.find('#generalParsleyError').length < 1
			) {
				parsleyField.parent.$element.prepend(errTempl);
			}

			parsleyField.parent.$element.find('#generalParsleyError').show();
		});

		$.listen('parsley:field:success', function (parsleyField) {
			if (parsleyField.parent.$element.find('parsley-error').length < 1) {
				parsleyField.parent.$element.find('#generalParsleyError').hide();
			}
		});
	}

	(function () {
		// Special validation for: Ich bin keine Landfrau case
		function requiredRelationValidation($left, $right) {
			if ($left.length < 1 || $right.length < 1) return;
			function validate(event) {
				var $this;
				var isFilledOut;
				if (event) {
					$this = $(this);
					thisIsFilledOut = $this.is($left) ? $this.val() : $this.is(':checked');
				}
				$left.removeAttr('disabled');
				$right.removeAttr('disabled');
				if ($left.val() === '' && !$right.is(':checked')) {
					$left.attr('required', 'required');
					$right.attr('required', 'required');
				} else if ($this && thisIsFilledOut) {
					if ($this.is($left)) {
						$left.attr('required', 'required');
						$right.removeAttr('required');
						$right.attr('disabled', 'disabled');
					} else {
						$right.attr('required', 'required');
						$left.removeAttr('required');
						$left.attr('disabled', 'disabled');
					}
				} else if ($left.val() !== '') {
					$left.attr('required', 'required');
					$right.removeAttr('required');
				} else if ($right.is(':checked')) {
					$right.attr('required', 'required');
					$left.removeAttr('required');
				}
				$left.parsley().reset();
				$right.parsley().reset();
			}

			$left.on('change', validate);
			$right.on('change', validate);

			validate();
		}

		// #region
		requiredRelationValidation($('#seminarForm #region'), $('#seminarForm #additional'));

		// #clubname
		requiredRelationValidation($('#seminarForm #clubname'), $('#seminarForm #additional'));

		// #department
		requiredRelationValidation($('#seminarForm #department'), $('#seminarForm #additional'));

		function companyRequiredOnlyIfNotLandfrau() {
			var $this = $(this);
			var $company = $('#seminarForm #company');

			if ($company.length) {
				if ($this.is(':checked')) {
					$company.attr('required', 'required');
				} else {
					$company.removeAttr('required');
				}
				$company.parsley().reset();
			}
		}

		$('#seminarForm #additional').on('change', companyRequiredOnlyIfNotLandfrau);

		companyRequiredOnlyIfNotLandfrau.call($('#seminarForm #additional'));
	})();
});
