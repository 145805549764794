$(document).ready(function() {
	
	var $portal = $(".portal");

	if($portal.length < 1){ return; }

	$portal.isotope({
		itemSelector: '.teaser'
	});

	$portal.imagesLoaded(function() {

		$portal.isotope('layout');
	});
});